import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";

import Modal from "./bootstrap/modal";
import Terms from "../components/portal/terms";
import CriticalInformation from "../components/portal/critical-information";
import { getFormData } from "./manager/form";
import { NON_INTERNET_PRODUCT_TYPES_TO_SHOW } from "./utils/constants";

const Review = (props) => {
  const {
    onClose,
    submit,
    product,
    purchaseError,
    firstName,
    lastName,
    email,
    mobile,
    cardHolderName,
    cardNumber,
    cardExpiryMonth,
    cardExpiryYear,

    unitNo,
    streetNo,
    suburb,
    state,
    postCode,

    unitNoShipping,
    streetNoShipping,
    addressShipping,
    suburbShipping,
    stateShipping,
    postCodeShipping,
  } = props;

  const [loading, setLoading] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const [checked, setCheck] = useAsyncSetState(false);
  const [consent, setConsent] = useAsyncSetState(false);

  const {
    serviceAvailability,
    newConnectionProduct,
    selectedProducts,
    data,
  } = getFormData();

  const plan = selectedProducts.find(
    ({ type }) => type === "internet"
  );
  const hardwareProductsDisplay = selectedProducts.filter(
    ({ type }) => type === "hardware"
  );
  const addOnProductsDisplay = selectedProducts.filter(
    ({type}) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(type)
  );
  
  const connectionFee = serviceAvailability.isNewConnection ? Number(newConnectionProduct?.value || 0) : 0;
  const addonsMonthly = (selectedProducts || [])
    .filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(p.type))
    .filter(({once}) => !once)
    .reduce((prev, current) => Number(current.value) + prev, 0);
  const addonOnce = (selectedProducts || [])
    .filter((p) => !NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(p.type))
    .filter(({once}) => once)
    .reduce((prev, current) => Number(current.value) + prev, 0);
  const originalPerMonth =
    (selectedProducts || [])
      .filter((p) => NON_INTERNET_PRODUCT_TYPES_TO_SHOW.includes(p.type))
      .filter(({once}) => !once)
      .reduce((prev, current) => (current.voucherName ? Number(current.originalValue) : Number(current.value)) + prev, 0) + plan.value;  
  const initialPayment = connectionFee + addonsMonthly + addonOnce + plan.value
  const total = connectionFee + (selectedProducts || []).reduce((o, p) => p.value + o, 0);
  const perMonth = Number(addonsMonthly + plan.value)

  const renderAcknowledgeText = () => {
    let ack = "";
    if (connectionFee > 0) {
      ack += `I acknowledge I will be charged $${connectionFee} for the New Developments Charge in the first month in addition to my plan charge/s. `;
    }
    if (product.voucherName && product.voucherUses > 1) {
      ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month for the first ${product.voucherUses} month/s and then ${originalPerMonth} per month, until cancelled. (First month: $${total}, next ${product.voucherUses - 1} month/s: ${perMonth}, thereafter: $${originalPerMonth})`;
    } else if (product.voucherName && product.voucherUses === 1) {
      ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month for the first month and then ${originalPerMonth} per month, until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
    } else {
      ack += `I acknowledge that I will be charged plan charges of $${perMonth} per month, until cancelled. (First month: $${total}, thereafter: $${originalPerMonth})`;
    }

    return ack;
  }

  return (
    <Modal
      show
      onClose={() => onClose && onClose()}
      title="Finalize Purchase"
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto">
              <Button
                disabled={loading || purchaseError !== null}
                variant="darkblue"
                onClick={() => onClose && onClose()}
              >
                <i className="fas fa-arrow-left mr-2" />
                {"Return and Edit"}
              </Button>
              <Button
                disabled={loading || !checked || !consent}
                variant="darkblue ml-3"
                onClick={async() => {
                  await setLoading(true);
                  if (submit) {
                    await submit();

                  }
                  return setLoading(false);
                }}
              >
                {loading ? "Please wait ... " : "Purchase"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Container className="review">
        <Row>
          <Col xs={6} className="mx-auto mb-3">
            <div className="product-container">
              <div className="name">{product?.name || ""}</div>
              <div className="price">{`$${Number(product.value)}`}</div>
              <div className="desc">{product?.description || ""}</div>
              <div className="edit-product" onClick={() => onClose && onClose()}>{"Edit"}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mx-auto">
            <Container>
              <Row>
                <Col xs={4}>
                  <div className="label">{"Name"}</div>
                  <div className="detail">{`${firstName} ${lastName}`}</div>
                </Col>
                <Col xs={4}>
                  <div className="label">{"Email"}</div>
                  <div className="detail">{email}</div>
                </Col>
                <Col xs={4}>
                  <div className="label">{"Mobile"}</div>
                  <div className="detail">{mobile}</div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="label">
                  {"Address"}
                </Col>
                <Col className="detail">{`${unitNo || ""} ${streetNo || ""} ${
                  suburb || ""
                } ${state || ""} ${postCode || ""}`}</Col>
              </Row>
              <Row>
                {(streetNoShipping || addressShipping || suburbShipping || stateShipping || postCodeShipping) && <Col xs={12} className="label">
                  {"Shipping Address"}
                </Col>}
                <Col className="detail">{`${unitNoShipping || ""} ${
                  streetNoShipping || ""
                } ${addressShipping || ""} ${suburbShipping || ""} ${
                  stateShipping || ""
                } ${postCodeShipping || ""}`}</Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div className="label">{"Card Name"}</div>
                  <div className="detail">{cardHolderName}</div>
                </Col>
                <Col xs={4}>
                  <div className="label">{"Card Number"}</div>
                  <div className="detail">
                    {`XXXX XXXX XXXX ${cardNumber.substring(
                      12,
                      cardNumber.length
                    )}`}
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="label">{"Card Expiry"}</div>
                  <div className="detail">{`${cardExpiryMonth}/${cardExpiryYear}`}</div>
                </Col>
              </Row>
              {hardwareProductsDisplay.length > 0 && (
                <>
                  <Row>
                    <Col xs={12} className="label">
                      {"Hardware Addons"}
                    </Col>
                  </Row>
                  <Row>
                    {hardwareProductsDisplay.map((product) => {
                      return (
                        <Col xs={6}>
                          <div key={product.id}>
                            {`${product.name}`}
                            <span className="font-orange ml-3 font-bold">
                              {`+$${product.value}${
                                product.once ? " Once off" : "/Month"
                              }`}
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
              {addOnProductsDisplay.length > 0 && (
                <>
                  <Row>
                    <Col xs={12} className="label">
                      {"Addons"}
                    </Col>
                  </Row>
                  <Row>
                    {addOnProductsDisplay.map((product) => {
                      return (
                        <Col xs={6} key={product.id}>
                          <div className="addons">
                            {`${product.name}`}
                            <span className="font-orange ml-3">
                              {`+$${product.value}${
                                product.once ? " Once off" : "/Month"
                              }`}
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}

              <Row>
                <Col xs={8} className="mx-auto my-3">
                  <div className="product-container">
                    {serviceAvailability?.isNewConnection && (newConnectionProduct?.value || 0) > 0 && <div>
                      <div className="name">{"New Development Charge (Once Off)"}</div>
                      <div className="price">
                        ${connectionFee}
                      </div>
                    </div>}
                    <div className="name">{"Initial Payment (Including First Month)"}</div>
                    <div className="price">
                      ${initialPayment}
                    </div>
                    <div className="name">{"Monthly"}</div>
                    <div className="price">
                      ${perMonth}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs="auto">
                  <div className="vw-checkbox">
                    <i
                      onClick={async () => setConsent(!consent)}
                      style={{ fontSize: 36 }}
                      className={`mr-2 ${
                        consent ? "far fa-check-square" : "far fa-square"
                      }`}
                    />
                    {renderAcknowledgeText()}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs="auto">
                  {showTerm && <Terms onClose={() => setTerm(false)} />}
                  {showPolicy && (
                    <CriticalInformation onClose={() => setPolicy(false)} />
                  )}
                  <div className="vw-checkbox">
                    <i
                      onClick={async () => setCheck(!checked)}
                      style={{ fontSize: 36 }}
                      className={`mr-2 ${
                        checked ? "far fa-check-square" : "far fa-square"
                      }`}
                    />
                    {"I agree to full"}
                    <a onClick={() => setTerm(true)} className="mx-1">
                      {"Terms of Service"}
                    </a>
                    {" and the "}
                    <a onClick={() => setPolicy(true)} className="mx-1">
                      {"Critical Information Summary"}
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default Review;
